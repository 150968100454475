
(function($) {
  var carouselVideo = $('#carousel-videos');
  if( 0 < carouselVideo.length ){
    $('#carousel-videos').slick({
      infinite:true,
      autoplay: false,
      pauseOnFocus : false,
      cssEase: 'linear',
      slidesToShow: 5,
      slideToScroll:1,
      variableWidth:true,
      swipeToSlide: true,
      arrows:true,
      prevArrow: '<button class="carousel__prev icon-back slick-prev"></button>',
      nextArrow: '<button class="carousel__next icon-next slick-next"></button>'

    });
  }
  if (0 < $('.popup-youtube').length ){
    $('.popup-youtube').magnificPopup({
      disableOn: 700,
      type: 'iframe',
      mainClass: 'mfp-fade',
      removalDelay: 160,
      preloader: false,
      fixedContentPos: false
    });
  }
})( jQuery );